import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../../graphical-content/data unfolding.png";
import sun from "../../graphical-content/Sun.svg";
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  const unfolding = () => {
    navigate('/');
  };
  const project = () => {
    navigate('/project');
  };
  const ourteam = () => {
    navigate('/ourteam');
  };
  const contactus = () => {
    navigate('/contactus');
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isMenuOpen &&
        !event.target.closest(".sidebar") &&
        !event.target.closest(".toggle-button")
      ) {
        setIsMenuOpen(false);
      }
    };

    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  return (
    <nav
      className={`border-gray-200 bg-white dark:border-gray-700 sticky top-0 z-50 ${
        isMenuOpen ? "menu-open" : ""
      }`}
    >
      <div className="max-w-screen-xl flex items-center justify-between mx-auto p-4">
        <div >
          <div onClick={unfolding} className="flex flex-row w-60 " >
            <img src={logo} alt="Logo" className="logo cursor-pointer" />
            <span className="cursor-pointer self-center text-2xl font-semibold whitespace-nowrap dark:text-black">
              Data Unfolding
            </span>
          </div>
        </div>

        <button
          onClick={toggleMenu}
          type="button"
          className="toggle-button inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-expanded={isMenuOpen}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>

        <div className="hidden w-full md:flex md:w-auto justify-end items-center space-x-8">
          <div
            onClick={project}
            className="text-black rounded-full cursor-pointer px-2 py-3 hover:text-blue-700 transition-colors duration-300"
          >
            Projects
          </div>
          <div onClick={ourteam} className="text-black cursor-pointer hover:text-blue-700">
            Team
          </div>
          <div
            onClick={contactus}
            className="text-gblack hover:text-blue-700 cursor-pointer whitespace-nowrap"
          >
            Contact Us
          </div>
        </div>
      </div>

      <div className="sidebar">
        <ul>
          <li>
            <Link to="/project" onClick={closeMenu} >Projects</Link>
          </li>
          <li>
            <Link to="/Ourteam" onClick={closeMenu} >Team</Link>
          </li>
          <li>
            <Link to="/ContactUs" onClick={closeMenu} >Contact Us</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
