// "use client";
import React from "react";
import image from '../../graphical-content/20944284 1.svg';
import analysisImage from '../../graphical-content/analysis_5958590 1.svg';
import softImage from '../../graphical-content/mobdev.svg';
import nlpImage from '../../graphical-content/nlp_10306100 1.svg';
import deepImage from '../../graphical-content/deep.png';
import AiImage from '../../graphical-content/AI.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import Timeline from '@mui/lab/Timeline';
import CustomTimelineItem from "./Desktop/CustomTimelineItem";
import CustomTimelineItemFirst from "./Desktop/CustomTimelineItemFirst";
import CustomTimelineItemLast from "./Desktop/CustomTimelineItemLast";
//import './customTimeline.css';
import { white } from "tailwindcss/colors";

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ServiceTitleDesktop from "./Desktop/ServiceTitleDesktop";
import ServiceDescriptionDesktop from "./Desktop/ServiceDescriptionDesktop";
import ServiceTitleMobile from "./Mobile/ServiceTitleMobile";
import ServiceDescriptionMobile from "./Mobile/ServiceDescriptionMobile";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';


function Services() {
  const isMobile = useMediaQuery('(max-width: 500px)');
  const isTablet = useMediaQuery('(min-width: 501px) and (max-width: 1190px)');
  const ServiceTitleComponent = isMobile ? ServiceTitleMobile : ServiceTitleDesktop;
  const ServiceDescriptionComponent = isMobile ? ServiceDescriptionMobile : ServiceDescriptionDesktop;
  console.log(window.innerWidth)
  console.log(isMobile)
  return (
    <>
      <div styles={{ background: '#0A082F' }}>
        <div
          style={{
            background: "#0A082F",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "1%",
            overflowX: 'hidden',
            flex: 1
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", paddingTop: "1.5%" }}
          >
            <div style={{ display: "flex", flexDirection: "row", justifyContent: 'center' }}>


              <ServiceTitleComponent color="#F29D77" text="Services" />
              <ServiceTitleComponent color="#FFFFFF" text="We Offer" style={{ marginLeft: '1rem' }} />
            </div>

            <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
              <ServiceDescriptionComponent
                fontSize="2.3em"
                fontWeight="500"
                fontFamily="Intro Catch, sans-serif"
                color="white"
                text="From Data Mumble to Clear Direction: We" />
              <ServiceDescriptionComponent
                fontSize="2.1em"
                fontWeight="500"
                fontFamily="Intro Catch, sans-serif"
                color="white"
                text="Conduct the Symphony of Your Success." />
            </div>
          </div>




          {isMobile ? (
            <>
              <div style={{  width: '100%' }}>
              <Timeline
                sx={{
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex:0,
                  },
                }}
              >
                <TimelineItem>
                  <TimelineOppositeContent color="textSecondary">

                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "#FFB532" }}>
                      <div style={{ width: '10vw', height: '10vw', borderRadius: '50%', backgroundColor: '#FFB532', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ color: 'Black', fontSize: '5vw', fontWeight: 'bold' }}>1</span>
                      </div>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 2, px: 1 }} >
                    <Typography variant="h4" component="span" color={white}>
                    Artificial Intelligence
                    </Typography>
                    <Typography variant="h6" color={white} >
                    Experience the future of business with our AI-based services. Harness data-driven insights and automation to drive efficiently and unlock growth opportunities. Lets transform your business together.
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="textSecondary">

                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "#FFB532" }}>
                      <div style={{ width: '10vw', height: '10vw', borderRadius: '50%', backgroundColor: '#FFB532', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ color: 'Black', fontSize: '5vw', fontWeight: 'bold' }}>2</span>
                      </div>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 2, px: 2 }} >
                    <Typography variant="h4" component="span" color={white}>
                    Data Science
                    </Typography>
                    <Typography variant="h6" color={white} >
                    Comprehensive data science solutions, from data exploration and feature engineering to model development and deployment. Predictive modelling, clustering, and classification to optimize business processes and improve performance.
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="textSecondary">

                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "#FFB532" }}>
                      <div style={{ width: '10vw', height: '10vw', borderRadius: '50%', backgroundColor: '#FFB532', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ color: 'Black', fontSize: '5vw', fontWeight: 'bold' }}>3</span>
                      </div>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 2, px: 2 }} >
                    <Typography variant="h4" component="span" color={white}>
                    Data Analytics
                    </Typography>
                    <Typography variant="h6" color={white} >
                    Expert data analytics leverage statistical methods and visualization tools to uncover patterns, trends, and outliers in your datasets. Customized analysis to extract meaningful insights that inform strategic business decisions.
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="textSecondary">

                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "#FFB532" }}>
                      <div style={{ width: '10vw', height: '10vw', borderRadius: '50%', backgroundColor: '#FFB532', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ color: 'Black', fontSize: '5vw', fontWeight: 'bold' }}>4</span>
                      </div>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 2, px: 2 }} >
                    <Typography variant="h4" component="span" color={white}>
                    Software Dev
                    </Typography>
                    <Typography variant="h6" color={white} >
                    Our Software Development service crafts versatile solutions, spanning cross-platform and native mobile apps, desktop applications, web platforms, and Elevated Software as a Service (SaaS) solutions, ensuring seamless experiences across diverse environments.
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="textSecondary">

                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "#FFB532" }}>
                      <div style={{ width: '10vw', height: '10vw', borderRadius: '50%', backgroundColor: '#FFB532', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ color: 'Black', fontSize: '5vw', fontWeight: 'bold' }}>5</span>
                      </div>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 2, px: 2 }} >
                    <Typography variant="h4" component="span" color={white}>
                    Deep Learning
                    </Typography>
                    <Typography variant="h6" color={white} >
                    Harnessing the power of neural networks for complex tasks such as image recognition, speech processing, and anomaly detection. State-of-the-art deep learning models to solve business challenges and unlock new opportunities.
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="textSecondary">

                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "#FFB532" }}>
                      <div style={{ width: '10vw', height: '10vw', borderRadius: '50%', backgroundColor: '#FFB532', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ color: 'Black', fontSize: '5vw', fontWeight: 'bold' }}>6</span>
                      </div>
                    </TimelineDot>
                    {/* <TimelineConnector /> */}
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 2, px: 2 }} >
                    <Typography variant="h4" component="span" color={white}>
                    NLP/Gen AI
                    </Typography>
                    <Typography variant="h6" color={white} >
                    Advanced Natural Language Processing(NLP) techniques for extracting valuable information from unstructured text data. Sentiment analysis, text classification, and language translation to enhance customer interactions and market understanding. Implementation of large language models for chatbots, document summarization, and knowledge extraction.
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
            </>
          ) : isTablet ? (
           
            <div style={{  width: '100%' }}>
              <Timeline
                sx={{
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0,
                  },
                }}
              >
                <TimelineItem>
                  <TimelineOppositeContent color="textSecondary">

                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "#FFB532" }}>
                      <div style={{ width: '5vw', height: '5vw', borderRadius: '50%', backgroundColor: '#FFB532', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ color: 'Black', fontSize: '3vw', fontWeight: 'bold' }}>1</span>
                      </div>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 2, px: 7 }}  >
                    <Typography variant="h2" component="span" color={white}>
                    Artificial Intelligence
                    </Typography>
                    <Typography variant="h6" color={white} textAlign={"justify"} >
                    Experience the future of business with our AI-based services. Harness data-driven insights and automation to drive efficiently and unlock growth opportunities. Lets transform your business together.
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="textSecondary">

                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "#FFB532" }}>
                      <div style={{ width: '5vw', height: '5vw', borderRadius: '50%', backgroundColor: '#FFB532', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ color: 'Black', fontSize: '3vw', fontWeight: 'bold' }}>2</span>
                      </div>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 2, px: 7 }} >
                    <Typography variant="h2" component="span" color={white}>
                    Data Science
                    </Typography>
                    <Typography variant="h6" color={white} textAlign={"justify"} >
                    Comprehensive data science solutions, from data exploration and feature engineering to model development and deployment. Predictive modelling, clustering, and classification to optimize business processes and improve performance.
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="textSecondary">

                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "#FFB532" }}>
                      <div style={{ width: '5vw', height: '5vw', borderRadius: '50%', backgroundColor: '#FFB532', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ color: 'Black', fontSize: '3vw', fontWeight: 'bold' }}>3</span>
                      </div>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 2, px: 7 }} >
                    <Typography variant="h2" component="span" color={white}>
                    Data Analytics
                    </Typography>
                    <Typography variant="h6" color={white} textAlign={"justify"} >
                    Expert data analytics leverage statistical methods and visualization tools to uncover patterns, trends, and outliers in your datasets. Customized analysis to extract meaningful insights that inform strategic business decisions.
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="textSecondary">

                  </TimelineOppositeContent >
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "#FFB532" }}>
                      <div style={{ width: '5vw', height: '5vw', borderRadius: '50%', backgroundColor: '#FFB532', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ color: 'Black', fontSize: '3vw', fontWeight: 'bold' }}>4</span>
                      </div>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 2, px: 7 }} >
                    <Typography variant="h2" component="span" color={white} >
                    Software Dev
                    </Typography>
                    <Typography variant="h6" color={white} textAlign={"justify"} >
                    Our Software Development service crafts versatile solutions, spanning cross-platform and native mobile apps, desktop applications, web platforms, and Elevated Software as a Service (SaaS) solutions, ensuring seamless experiences across diverse environments.
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="textSecondary">

                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "#FFB532" }}>
                      <div style={{ width: '5vw', height: '5vw', borderRadius: '50%', backgroundColor: '#FFB532', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ color: 'Black', fontSize: '3vw', fontWeight: 'bold' }}>5</span>
                      </div>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 2, px: 7 }} >
                    <Typography variant="h2" component="span" color={white}>
                    Deep Learning
                    </Typography>
                    <Typography variant="h6" color={white} textAlign={"justify"} >
                    Harnessing the power of neural networks for complex tasks such as image recognition, speech processing, and anomaly detection. State-of-the-art deep learning models to solve business challenges and unlock new opportunities.
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="textSecondary">

                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: "#FFB532" }}>
                      <div style={{ width: '5vw', height: '5vw', borderRadius: '50%', backgroundColor: '#FFB532', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ color: 'Black', fontSize: '3vw', fontWeight: 'bold' }}>6</span>
                      </div>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 2, px: 7 }} >
                    <Typography variant="h2" component="span" color={white}>
                    NLP/Gen AI
                    </Typography>
                    <Typography variant="h6" color={white} textAlign={"justify"}>
                    Advanced Natural Language Processing(NLP) techniques for extracting valuable information from unstructured text data. Sentiment analysis, text classification, and language translation to enhance customer interactions and market understanding. Implementation of large language models for chatbots, document summarization, and knowledge extraction.
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
          ) : (
            <>
              <Timeline position="alternate" sx={{ marginTop: '2.5 rem' }}>
                <CustomTimelineItemFirst
                  imageSrc={AiImage}
                  title="Artificial Intelligence"
                  description="Experience the future of business with our AI-based services. Harness data-driven insights and automation to drive efficiently and unlock growth opportunities. Lets transform your business together."
                  number="1" />
                <CustomTimelineItem
                  imageSrc={image}
                  title="Data Science"
                  description="Comprehensive data science solutions, from data exploration and feature engineering to model development and deployment. Predictive modelling, clustering, and classification to optimize business processes and improve performance."
                  number="2" />
                <CustomTimelineItem
                  imageSrc={analysisImage}
                  title="Data Analytics"
                  description="Expert data analytics leverage statistical methods and visualization tools to uncover patterns, trends, and outliers in your datasets. Customized analysis to extract meaningful insights that drive strategic business decisions."
                  number="3" />
                <CustomTimelineItem
                  
                   imageSrc={nlpImage}
                  title="NLP/Gen AI"
                  description="Advanced Natural Language Processing (NLP) techniques for extracting valuable information from unstructured text data. Sentiment analysis, text classification, and language translation to enhance customer's interactions and market understanding. Implementation of large language models for chatbots, document summarization, and knowledge extraction."
                  number="4" />

                <CustomTimelineItem
                  imageSrc={deepImage}
                  title="Deep Learning"
                  description="Harnessing the power of neural networks for complex tasks such as image recognition, speech processing, and anomaly detection. State-of-the-art deep learning models to solve business challenges and unlock new opportunities."
                  number="5" />

                <CustomTimelineItemLast
                  imageSrc={softImage}
                  title="Software Dev"
                  description="Our Software Development service crafts versatile solutions, spanning cross-platform and native mobile apps, desktop applications, web platforms, and Elevated Software as a Service (SaaS) solutions, ensuring seamless experiences across diverse environments."
                  number="6" />

              </Timeline>
            </>
          )}
        </div>
      </div >
    </>
  );
}

export default Services;
