import React from "react";

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { white } from "tailwindcss/colors";
//import './customTimeline.css'; // Import the CSS file


function CustomTimelineItemFirst({ imageSrc, title, description, number }) {
  return (
    <TimelineItem>
      <TimelineOppositeContent
        sx={{ m: 'auto 0' }}
        align="right"
        variant="body2"
        color="text.secondary"
      >
        <img
          src={imageSrc}
          alt="SVG"
          width="50%"
          height="50%"
          className="timeline-image"
          style={{
            display: 'block',
            width: '50%', // Adjust width as needed
            height: '50%', // Adjust height as needed
            margin: 'auto',
          }}
        />
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector style={{ height: '0.1em', width: '0.5em', backgroundImage: 'linear-gradient(to bottom, rgba(15, 11, 3, 0.9), #FFB532)', marginTop:'2em'}} />
        <TimelineDot style={{ backgroundColor: "#FFB532" }}>
          <div style={{ width: '8vw', height: '8vw', borderRadius: '50%', backgroundColor: '#FFB532', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ color: 'Black', fontSize: '4vw', fontWeight: 'bold' }}>{number}</span>
          </div>
        </TimelineDot>
        <TimelineConnector style={{ height: '1em', width: '0.5em', backgroundColor: '#4F4F4F' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: 30, px: 2 }}>
      <div style={{width:'100%',   textAlign:'left' ,}}>
        <Typography variant="h3" component="span" color={white}  textAlign={"center"} marginLeft={12}>
          {title}
        </Typography></div>
        <Typography variant="h5" color={white} marginLeft={12} marginRight={12} sx={{ fontSize: '1.3rem' }} >
          {description}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

export default CustomTimelineItemFirst;