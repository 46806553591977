import React from "react";

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { white } from "tailwindcss/colors";
function CustomTimelineItem({ imageSrc, title, description, number }) {
  return (
    <TimelineItem >
      <TimelineOppositeContent
        sx={{ m: 'auto', display: 'flex', alignItems: 'center', }}
        variant="body2"
        color="text.secondary"
      >
        <img src={imageSrc} alt="svg" style={{ width: '50%', height: '50%', marginTop:'0',paddingBottom:'30%', margin:'auto' }} className="timeline-image" />
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot style={{ backgroundColor: "#FFB532", }}>
          <div style={{ width: '8vw', height: '8vw', borderRadius: '50%', backgroundColor: '#FFB532', display: 'flex', alignItems: 'center', justifyContent: 'center',  }}>
            <span style={{ color: 'Black', fontSize: '4vw', fontWeight: 'bold' }}>{number}</span>
          </div>
        </TimelineDot>
        <TimelineConnector style={{ height: '5em', width: '0.5em', backgroundColor: '#4F4F4F' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: 0, px: 2}} >
      <div  className="overflow-x-auto whitespace-nowrap" style={{width:'100%', textAlign:'left' ,overflowX: 'auto',}}>
        <Typography variant="h2" component="span" color={white}  textAlign={"center"} marginLeft={12} >
          {title}
        </Typography></div>
       
        <Typography  variant="h5" color={white} marginLeft={12} textAlign={"left"} marginRight={12} sx={{ fontSize: '1.3rem' }}>
          {description}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

export default CustomTimelineItem;