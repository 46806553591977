import "./Projects.css"
import React, { useEffect, useState } from 'react';
import projects from './ProjectsData'

function Project() {
  var x = 0
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="mainContainer">
        <h1 className='headingProject'>Projects</h1>
        <div className="responsive-line" style={{ marginLeft:"40%", width: "22%", height: "8px", display: "flex" }}>
          {[...Array(100)].map((_, index) => (
              <div key={index} style={{ flex: 1, opacity:  1 - (index / 100), backgroundColor: "#FFB532" }} />
          ))}
        </div>
        <div className="mainClass">

          <p className="commingsoon">Coming Soon...</p>
          


          {/* {screenWidth > 425 && projects.map(project => (
            !x ? (
              (x = 1),
              <div className='containers'>
                {screenWidth > 425 && <div className="projectImage" style={{backgroundImage: `url(${project.imageURL})`}}/>}
                <div className='text left'>
                  <div className='title'>{project.title}</div>
                  <div className='description'>{project.description}</div>
                  <button className="Button">View Project</button>
                </div>
              </div>
            ) : (
              (x = 0),
              <div className='containers'>
                <div className='text right'>
                  <div className='title'>{project.title}</div>
                  <div className='description'>{project.description}</div>
                  <button className="Button">View Project</button>
                </div>
                {screenWidth > 425 && <div className="projectImage" style={{backgroundImage: `url(${project.imageURL})`}}/>}
                </div>
              
            )
          ))}

          {screenWidth <426 && projects.map(project => (
            <div className='containers'>
                <div className="noBackground">
                  <img className="image left" src={project.imageURL} alt={project.title} />
                </div>
            <div className='text'>
              <div className='title'>{project.title}</div>
              <div className='description'>{project.description}</div>
              <button className="Button">View Project</button>
            </div>
          </div>
          ))} */}
        </div> 
      </div>

      
    </>
  );
}

export default Project