import styled from 'styled-components';

export const PageContainer = styled.div`
   background-color: #0A082F;
  min-height: 100vh; /* Use 100vh to cover the entire viewport height */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children vertically */
  justify-content: center; /* Center children horizontally */
  overflow-x: hidden; /* Hide horizontal overflow */
  /* Add other styles for your page layout */
`;