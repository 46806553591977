"use client";
import React from "react";
import { AnimatedTooltip } from "./Animated-tooltip";
import "./Animated-tooltip.css"
import DirectionAwareHover from "./test";
import Abdullah from "../Images/Abdullah.jpg"
import Muaz from "../Images/Muaz.jpg"
import Raheeb from "../Images/Raheeb2.jpeg"
import Sahar from "../Images/saharrr.jpg"
import Mustafa from "../Images/Mustafa.jpg"
import Hasham from "../Images/Hasham.jpg"
import Ans from "../Images/Ans.jpg"
import Bilal from "../Images/Bilal.jpg"
import { PageContainer } from "./styled";
import YourComponent from "./CoFounder";
import Index from "./CoFounder";
import CoFounderCards from "./CoFounder";
// import TeamMember from "./CoFounderCards";
const people = [
  {
    id: 1,
    name: "Mustafa Raja",
    designation: "Mobile Developer",
    image:Mustafa,
  },
  {
    id: 2,
    name: "Hasham Asad",
    designation: "Game Developer",
    image:Hasham,
  },
  {
    id: 3,
    name: "Bilal Munir",
    designation: "Backend Developer",
    image:Bilal
  },
  {
    id: 4,
    name: "Raheeb Gill",
    designation: "Fullstack Developer",
    image:Raheeb,
  },
  {
    id: 5,
    name: "Sahar Arif",
    designation: "Backend Developer",
    image:Sahar,
  },
  {
    id: 6,
    name: "Muaz Asim",
    designation: "Frontend Developer",
    image:Muaz,
  },
  {
    id: 7,
    name: "M.Abdullah",
    designation: "Intern-Backend",
    image: Abdullah,
  
  },
  {
    id: 8,
    name: "Syed Ans",
    designation: "Intern-Frontend",
    image:Ans
  },
];

export function AnimatedTooltipPreview() {
  return (
    <div>
    <PageContainer>
      <div style={{marginTop:"10px" }}>
      <div>
        <h1 className="text-5xl font-bold mb-8 center-text"style={{marginBottom:"5px"}}>Our Team</h1>
        <div className="responsive-line" style={{ marginLeft:"38%", width: "25%", height: "8px", display: "flex",marginBottom:"10%" }}>
        {[...Array(100)].map((_, index) => (
            <div key={index} style={{ flex: 1, opacity:  1 - (index / 100), backgroundColor: "#FFB532" }} />
        ))}
    </div>
    <div style={{marginBottom:"-2%"}}>
    {/* <h1 className="text-5xl font-bold mb-8"style={{color:"white",marginLeft:"24%"}}>DIRECTOR</h1>
    <h1 className="text-5xl font-bold mb-8"style={{color:"white",marginRight:"24%"}}>DIRECTOR</h1> */}
    </div>
    {/* <DirectionAwareHover /> */}
    <CoFounderCards/>
    {/* <Index /> */}
    {/* <TeamMember/> */}
    
    
    
       
    <div className="flex flex-row items-center justify-center mb-10 w-full"style={{marginTop:"5%" }} > 
    
    
      <AnimatedTooltip items={people} />
    </div>
    </div>
    </div> 
    </PageContainer>
    </div>
  );
}
