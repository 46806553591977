import React from "react";

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { white } from "tailwindcss/colors";


function CustomTimelineItemLast({ imageSrc, title, description, number }) {
  return (
    <TimelineItem >
    
    <TimelineOppositeContent
      sx={{ m: 'auto', display: 'flex', alignItems: 'center' }}
      variant="body2"
      color="text.secondary"
    >
      <img src={imageSrc} alt="SVG" style={{width: '50%', height: '50%', paddingBottom:'20%', margin:'auto'}} />
      
    </TimelineOppositeContent>
    
    <TimelineSeparator>
    {/* <TimelineConnector style={{ height: '1em', width: '1em', backgroundColor: '#4F4F4F' }} /> */}
      <TimelineDot style={{ backgroundColor: "#FFB532" }}>
        <div style={{ width: '8.5vw', height: '8.5vw', borderRadius: '50%', backgroundColor: '#FFB532', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ color: 'Black', fontSize: '4vw', fontWeight: 'bold' }}>{number}</span>
        </div>
      </TimelineDot>
    </TimelineSeparator>
    <TimelineContent sx={{ py: 0, px: 2 }} style={{marginBottom:'5%'}}>
      <div style={{width:'100%',   textAlign:'left' , }}>
        <Typography variant="h2" component="span" color={white}  textAlign={"center"} marginLeft={12}>
          {title}
        </Typography></div>
        <Typography  color={white} marginLeft={12} textAlign={"left"} marginRight={12} sx={{ fontSize: '1.3rem' }}>
          {description}
        </Typography>
      </TimelineContent>
  </TimelineItem>
  );
}

export default CustomTimelineItemLast;