// import React, { useState } from "react";
// import { motion, AnimatePresence } from "framer-motion";

// const cards = [
//   [
//     { id: 1, color: "#38182F", title: "card 1",content: "Card 1 Content" },
//     { id: 2, color: "#2F394D", title: "card 2", content: "Card 2 Content" },
//   ],
//   [
//     { id: 3, color: "#56666B", title: "card 3", content: "Card 3 Content" },
//     { id: 4, color: "#330036", title: "card 4", content: "Card 4 Content" },
//   ],
// ];

// function KeyDifferentiators() {
//   return (
//     <>
//     <div style={{ textAlign: 'center', flexGrow: 1, flexShrink: 0 , overflowX:'hidden'}}>
//   <h1 style={{ fontSize: '3.5rem', fontWeight: '600', padding:'2%' }}>Key Differentiators</h1>
// </div> 
//       {cards.map((card, index) => (
//         <Card key={index} card={card} />
//       ))}
//     </>
//   );
// }

// const Card = ({ card }) => {
//   return (
//     <>
//     <div style={{ display: "flex", flexDirection: "row", height: '300px', margin: '10px' }}>
//       <motion.div
//         style={{
//           margin: '5px',
//           backgroundColor: card[0].color,
//           borderRadius: "10px",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           cursor: "pointer",
//           width: "100%",
//           height: "100%",
//           position: "relative",
//         }}
//         whileHover={{
//           width: "75%",
//           transition: { duration: 0.5 },
//         }}
//         initial={{ width: "50%" }}
//         layout
//       >
//         {card[0].title}
//       </motion.div>
//       <motion.div
//         style={{
//           margin: '5px',
//           backgroundColor: card[1].color,
//           borderRadius: "10px",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           cursor: "pointer",
//           width: "100%",
//           height: "100%",
//           position: "relative",
//         }}
//         whileHover={{
//           width: "75%",
//           transition: { duration: 0.5 },
//         }}
//         initial={{ width: "50%" }}
//         layout
//       >
//         {card[1].title}
//       </motion.div>
//     </div></>
//   );
// };

// export default KeyDifferentiators;


import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const cards = [
  [
    { id: 1, color: "linear-gradient(to bottom right, #FC5130, #FF8269)", title: "Expertise", description:"Meet the Minds Behind Your Success: Our Expert Team",
    content: "Comprehensive data science solutions, from data exploration and feature engineering to model development and deployment. Predictive modelling, clustering, and classification to optimize business processes and improve performance." },
    { id: 2, color: "linear-gradient(to bottom right, #114432, #2BAAA3)", title: "Innovation",  description:"Future-Proof Solutions: Powered by Cutting-Edge Technology",
    content: "We stay at the forefront of technological advancements, integrating the latest tools and methodologies to ensure our clients benefit from the most innovative solutions." },
  ],
  [
    { id: 3, color: "linear-gradient(to bottom right, #2F4858, #659BBE)", title: "Customization",  description:"Bridging the Gap: Bespoke Solutions for Your Unique Business Challenges",content: "Tailoring our services to meet the unique needs of each client, we provide bespoke solutions that align with specific business objectives and challenges." },
    { id: 4, color: "linear-gradient(to bottom right, #303036, #8B8B9C)", title: "Scalability",  description:"Grow with Confidence: Scalable Data Science for Long-Term Success",content: "Our solutions are designed to scale with your business, accommodating increased data volumes and evolving analytical requirements." },
  ],
];

function KeyDifferentiators() {
  const [isMobile, setIsMobile] = useState(false);
  return (
    <>
      <div style={{ textAlign: 'center', flexGrow: 1, flexShrink: 0 , overflowX:'hidden'}}>
        <h1 style={{ fontSize: '3rem', fontWeight: '600', padding:'2%' }}>Key Differentiators</h1>
      </div> 
      {cards.map((cardRow, index) => (
        <div key={index} style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          {cardRow.map((card) => (
            <Card key={card.id} card={card} />
          ))}
        </div>
      ))}
    </>
  );
}
const Card = ({ card }) => {
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = window.innerWidth < 450;
const isTablet = window.innerWidth >= 450 && window.innerWidth < 600;
  return (
    <motion.div
  style={{
    margin: '5px',
    backgroundImage: card.color,
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: isHovered ? "75%" : "50%",
    height: "300px",
    position: "relative",
    overflow: 'hidden'
  }}
  whileHover={{
    width: "75%",
    transition: { duration: 0.5 },
  }}
  initial={{ width: "50%" }}
  layout
  onHoverStart={() => {
    setIsHovered(true);
  }}
  onHoverEnd={() => {
    setIsHovered(false);
  }}
>
  {isHovered ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.3, duration: 0.6 }}
    >
      <p style={{ fontSize: isMobile ? '1.2rem' : '1.6rem', flexGrow: 1, textAlign: 'left', padding: '5%', opacity: 0.9, color: 'white' }}>{card.content}</p>
    </motion.div>
  ) : (
    <div style={{ padding: isMobile ? '0px' : '20px', textAlign: 'left', position: 'absolute', bottom: isMobile ? '10px' : '10px', left: isMobile ? '15px' : '20px', flexGrow: 1 }}>
    <p style={{
    fontSize: isTablet ? '2rem' : (isMobile ? '1.5rem' : '2.6rem'),
    fontWeight: '400',
    marginBottom: isMobile || isTablet ? '0.2rem' : '0.5rem',
    color: 'white'
}}>
    {card.title}
</p>
     <p style={{
    fontSize: isTablet ? '1rem' : (isMobile ? '0.75rem' : '1.4rem'),
    color: 'white',
    paddingRight: '25%',
    opacity: '0.8',
    marginBottom: isMobile || isTablet ? '0.2rem' : '0.5rem'
}}>
    {card.description}
</p>
    </div>
  )}
</motion.div>
  );
};

export default KeyDifferentiators;
