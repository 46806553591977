import React from "react";
import { motion } from "framer-motion";
import { cn } from "../../utils/cn.ts";

export const BoxesCore = ({ className, ...rest }: { className?: string }) => {
  
  const rows = new Array(30).fill(1); // Adjust the number of rows
  const cols = new Array(50).fill(1); // Adjust the number of columns
  let colors = [
    "--sky-300",
    "--pink-300",
    "--green-300",
    "--yellow-300",
    "--red-300",
    "--purple-300",
    "--blue-300",
    "--indigo-300",
    "--violet-300",
  ];
  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };
  const calculateOpacity = (i: number, j: number) => {
    const centerI = rows.length / 2;
    const centerJ = cols.length / 2;
    const distanceFromCenter = Math.sqrt(Math.pow(i - centerI, 2) + Math.pow(j - centerJ, 2));
    const maxDistance = Math.sqrt(Math.pow(centerI, 2) + Math.pow(centerJ, 2));
    const opacity = 1 - distanceFromCenter / maxDistance;
    // Additional opacity adjustment for lines closer to the center
    return opacity < 0.001 ? opacity : 0.7; // Limit opacity to a maximum of 0.5
  };

  return (
    <>
   <div className="absolute inset-0 w-full h-full bg-white z-10" style={{ maskImage: 'radial-gradient(ellipse at center, transparent, white 90%)', pointerEvents: 'none' }}></div>

    <div
      className={cn(
        "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-0", // Center the boxes
        className
      )}
      {...rest}
      style={{
        backgroundImage: "radial-gradient( transparent, white)",
      }}
    >
      {rows.map((_, i) => (
        <motion.div key={`row` + i} className="flex">
          {cols.map((_, j) => (
            <motion.div
              key={`col` + j}
              whileHover={{
                backgroundColor: `var(${getRandomColor()})`,
                transition: { duration: 0 },
              }}
              animate={{
                transition: { duration: 1 },
              }}
              style={{
                opacity: calculateOpacity(i, j),
              }}
              className="w-8 h-8 border border-slate-700/10" // Simplified border style
            ></motion.div>
          ))}
        </motion.div>
      ))}
    </div></>
  );
};

export const Boxes = React.memo(BoxesCore);
