import React from "react";

const ServiceDescriptionMobile = ({ fontSize, fontWeight, fontFamily, color, text }) => {
    return (
      <div style={{ color }}>
        <p
          style={{
            fontSize: "1em",
            paddingTop:'1%',
            fontWeight: "300",
            fontFamily: "Intro Catch, sans-serif",
            
          }}
        >
          {text}
        </p>
      </div>
    );
  };


  export default ServiceDescriptionMobile;