import React from "react";
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Services from "./components/Services/Services";
import KeyDifferentiators from "./components/Services/KeyDifferentiators";
import Project from "./components/Projects/Projects";
import ContactUs from "./components/ContactUs/ContactUs";
import Main from "./components/index/Main" ;
import Navbar from "./components/Navbar/Navbar";
import HamburgerNavbar from "./components/hamburger/HamburgerNavbar";
import useMediaQuery from '@mui/material/useMediaQuery';
import Footer from "./components/Footer/Footer";
import { AnimatedTooltip } from "./tooltip/Animated-tooltip";
import { AnimatedTooltipPreview } from "./tooltip/AnimatedTooltipPreview";
import {DirectionAwareHover} from "./tooltip/test"
import Resource from "./components/Resource/Resource";
import useScrollToTop from "./hooks/useScrollToTop";
function MainContent() {
  useScrollToTop();

  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/Services" element={<Services />} />
      <Route path="/KeyDifferentiators" element={<KeyDifferentiators />} />
      <Route path="/project" element={<Project />} />
      <Route path="/ourteam" element={<AnimatedTooltipPreview />} />
      <Route path="/contactUs" element={<ContactUs />} />
      <Route path="/test" element={<DirectionAwareHover />} />
      <Route path="/Resource" element={<Resource />} />
    </Routes>
  );
}

function App() {
  const isMobile = useMediaQuery('(max-width: 510px)');

  return (
    <Router>
      <div className="App">
        <Navbar />
        <MainContent />
        <Footer />
      </div>
    </Router>
  );
}

export default App;