const projects = [
    {
        title: "Khabay",
        description: "\"Khabay\" is a real-time meetup planner app that allows you to plan meetups with your friends and recommends top restaurants based on reviews, whether you're craving desi or fast food. ",
        imageURL: require("../../Images/1.jpeg")
    },
    {
        title: "Child's Tracheostomy Care",
        description: "\"Child's Tracheostomy Care\" is a mobile application developed with React Native that provides parents and caregivers with essential guidance and tools for managing tracheostomy care for children. From step-by-step instructions for suctioning and changing the tracheostomy tube to tracking medication schedules and doctor appointments, this app aims to empower caregivers with the knowledge and resources they need to ensure the well-being of their child with a tracheostomy. ",
        imageURL: require("../../Images/2.jpeg")
    },
    {
        title: "Diabatic Ratinography",
        description: "MrWhoseTheBoss, discovering what's best for you means exploring various activities and experiences that align with your interests and preferences. It's about finding what excites and fulfills you.  ",
        imageURL: require("../../Images/3.jpeg")
    }
]

export default projects;