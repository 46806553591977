import React from "react";

const ServiceTitleDesktop = ({ color, text, style }) => {
  return (
    <div style={{ color, ...style }}>
      <p
        style={{
          fontSize: "3.5em",
          fontWeight: "500",
          fontFamily: "Intro Catch, sans-serif",
        }}
      >
        {text}
      </p>
    </div>
  );
};
export default ServiceTitleDesktop;