import React from "react";
import { useLocation } from 'react-router-dom';
import fb from './GraphicsFooter/facepng.png';
import { useNavigate } from 'react-router-dom';
import twitter from './GraphicsFooter/twitterpng.png';
import insta from './GraphicsFooter/instapng.png';
import linkedin from './GraphicsFooter/linkpng.png';
import { HashLink } from "react-router-hash-link";
const Footer = () => {
  const navigate = useNavigate();
  const Resource = () => {
    navigate('/Resource');
  };
  const project = () => {
    navigate('/project');
  };
  const ourteam = () => {
    navigate('/ourteam');
  };
  const contactus = () => {
    navigate('/contactus');
  };
  return (
      <footer className="bg-gray-800 pt-10 pb-1 sm:pt-20 sm:pb-2">
      <div className="px-4 py-8">
        <div className="flex flex-wrap justify-between">
          <div className="w-full min-[320px]:w-1/2 sm:w-1/2 md:w-auto mb-6 sm:mb-8 md:mb-0">
            <h4 className="text-white text-sm font-bold mb-4">For Business</h4>
            {/* <a href="/" className="text-gray-300 text-sm block mb-2">Services</a> */}
            <HashLink className="text-gray-300 text-sm block mb-2 hover:text-slate-200" to='/#service' >Services</HashLink>
            <HashLink to="/#keyDiffs" className="text-gray-300 text-sm block mb-2 hover:text-slate-200"> Key Differentiators</HashLink>
           
          </div>
          <div className="w-full min-[320px]:w-1/2 sm:w-1/2 md:w-auto mb-6 sm:mb-8 md:mb-0">
            <h4 className="text-white text-sm font-bold mb-4">Resources</h4>
            <div onClick={Resource} className="text-gray-300 text-sm block mb-2 cursor-pointer hover:text-slate-200">Resource center</div>
         
          </div>
          <div className="w-full min-[320px]:w-1/2 sm:w-1/2 md:w-auto mb-6 sm:mb-8 md:mb-0">
            <h4 className="text-white text-sm font-bold mb-4">Partners</h4>
           
          </div>
          <div className="w-full min-[320px]:w-1/2 sm:w-1/2 md:w-auto mb-6 sm:mb-8 md:mb-0">
            <h4 className="text-white text-sm font-bold mb-4">Company</h4>
           
            <div onClick={project} className="text-gray-300 text-sm block mb-2 cursor-pointer hover:text-slate-200">Project</div>
            <div onClick={ourteam} className="text-gray-300 text-sm block mb-2 cursor-pointer hover:text-slate-200">Team</div>
            <div onClick={contactus} className="text-gray-300 text-sm block mb-2 cursor-pointer hover:text-slate-200">Contact Us</div>
          </div>
          <div className="w-full  md:w-auto mb-6 sm:mb-8 md:mb-0">
            
            <div className="flex justify-center">
            <a href=  "https://www.facebook.com/share/5tdAJbxTomDG9JmM/?mibextid=qi2Omg">   <img src={fb} alt="Facebook" className="w-10 mr-2" /></a>
            <a href= "">    <img src={twitter} alt="Twitter" className="w-10 mr-2" /></a>
            <a href=  "https://www.linkedin.com/company/data-unfolding">   <img src={linkedin} alt="LinkedIn" className="w-10 mr-2" /></a>
            <a href=  "https://www.instagram.com/dataunfolding">    <img src={insta} alt="Instagram" className="w-10 mr-2" /></a>
            </div>
          </div>
        </div>
        <hr className="bg-white w-full my-8" />
        {/* <h4 className=" flex justify-center text-white text-sm font-bold mb-4">Coming soon on</h4> */}
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="text-white mb-4 sm:mb-0">
            <p>@2024 Data Unfolding. All rights reserved.</p>
          </div>
          <div className="text-white mb-4 sm:mb-0">
    <p>Built and Maintained by Data Unfolding</p>
  </div>
        </div>
        
        {/* <h4 className=" flex justify-center text-white text-sm font-bold mb-4">Coming soon on</h4> */}
       
      </div>
    </footer>
  );
}

export default Footer;