import React from "react";

const ServiceTitleMobile = ({ color, text, style }) => {
    return (
      <div style={{ color, ...style }}>
        <p
          style={{
            paddingTop:'7%',
            fontSize: "2em",
            fontWeight: "500",
            fontFamily: "Intro Catch, sans-serif",
          }}
        >
          {text}
        </p>
      </div>
    );
  };

  export default ServiceTitleMobile;