import "./Contactus.css"
import phone from "../../Images/icons8-phone-100.png"
import mail from '../../Images/icons8-mail-100.png'
import { useState } from "react";
import emailjs from '@emailjs/browser';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_subject: "",
    message: ""
  });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_0c14ori',
        'template_lgyftho',
        e.target,
        {
          publicKey: 'i15wHNpyUoS2hp1gX',
        }
      )
      .then(
        () => {
          console.log('SUCCESS!');
          // Reset form fields
          setFormData({
            user_name: "",
            user_email: "",
            user_subject: "",
            message: ""
          });
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <div className="background_div">
        <h1 className="mainHeading">Contact Us</h1>
        <form className="ContactForm" onSubmit={sendEmail}>
          <p>Need details about our Business plan?</p>
          <p className="letusknow">Let us know.</p>

          <label htmlFor="name">Your Name:</label>
          <input
            className="name"
            name="user_name"
            type="text"
            placeholder="John Smith"
            value={formData.user_name}
            onChange={handleChange}
            required
          />

          <label htmlFor="email">Your Email:</label>
          <input
            className="email"
            name="user_email"
            type="text"
            placeholder="abc.xyz@gmail.com"
            value={formData.user_email}
            onChange={handleChange}
            required
          />

          <label htmlFor="subject">Subject:</label>
          <input
            className="subject"
            name="user_subject"
            type="text"
            value={formData.user_subject}
            onChange={handleChange}
            required
          />

          <label htmlFor="message">Your Message:</label>
          <textarea
            className="message"
            name="message"
            type="text"
            placeholder="Leave your message....."
            value={formData.message}
            onChange={handleChange}
            required
          />

          <button type="submit">Send Message</button>
        </form>
        <div className="contactInfo">
          <div className="Info">
            <div className="grayBox">
              <div className="Image">
                <img className="images" src={mail} alt="Mail" />
              </div>
            </div>
            <h3 className="heads">Email Us</h3>
            <div className="mailText">
              <p className="Texts">Email us for general queries, including marketing and partnership opportunities.</p>
              <p className="contacts">dataunfolding@gmail.com</p>
            </div>
          </div>
          <div className="Info">
            <div className="grayBox">
              <div className="Image">
                <img className="images" src={phone} alt="Phone" />
              </div>
            </div>
            <h3 className="heads">Call Us</h3>
            <div className="phoneText">
              <p className="Texts">Call us to speak to a member of our team. We are always happy to help you.</p>
              <p className="contacts">+92 (333) 44768246 <br/>+92 (321) 0002246</p>
              {/* <p className="contacts" style={{ paddingTop: 0 }}>+92 (321) 0002246</p> */}
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default ContactUs
