import React, { useState, useEffect } from "react";
import { motion, useMotionValue, useSpring, useTransform } from "framer-motion";
import "./Animated-tooltip.css";

export const AnimatedTooltip = ({ items }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const springConfig = { stiffness: 100, damping: 5 };
  const x = useMotionValue(0);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const rotate = useSpring(useTransform(x, [-100, 100], [-45, 45]), springConfig);
  const translateX = useSpring(useTransform(x, [-150, 150], [-80, 80]), springConfig);

  const handleMouseMove = (event) => {
    const halfWidth = event.target.offsetWidth / 2;
    x.set(event.nativeEvent.offsetX - halfWidth);
  };

  const getTooltipPosition = (tooltipWidth, offsetX, isLastItem) => {
    const viewportWidth = screenWidth;
    let isMobile = window.innerWidth < 900;
    let tooltipOffset = 22;

    let leftPosition = offsetX - tooltipWidth / 2;

    if (isLastItem && isMobile) {
      tooltipOffset -= 80; // Increase offset for last item on mobile
    }
    else if(window.innerWidth<900){
      tooltipOffset -=35
    }

    if (leftPosition < 0) {
      leftPosition = tooltipOffset;
    } else if (leftPosition + tooltipWidth > viewportWidth) {
      leftPosition = viewportWidth - tooltipWidth - tooltipOffset;
    }

    return leftPosition;
  };

  console.log(items); // Log items array to inspect data

  return (
    <>
      {items && Array.isArray(items) && items.map((item, idx) => (
        <div style={{marginTop:"5%"}}>
        <div
          className="-mr-4 relative group"
          key={item.name}
          onMouseEnter={() => setHoveredIndex(item.id)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          {console.log("hoveredIndex:", hoveredIndex, "item.id:", item.id)}
          {hoveredIndex === item.id && (
            <motion.div
              initial={{ opacity: 0, y: 20, scale: 0.6 }}
              animate={{
                opacity: 1,
                y: 0,
                scale: 1,
                transition: { type: "spring", stiffness: 260, damping: 10 },
              }}
              exit={{ opacity: 0, y: 20, scale: 1 }}
              style={{
                translateX: translateX,
                rotate: rotate,
                whiteSpace: "nowrap",
                left: getTooltipPosition(260, x.get(), idx === items.length - 1, screenWidth < 640),
                transform: "translateX(-50%)",
                top: screenWidth < 640 ? "-60%" : "-40%",
              }}
              className="absolute -top-16 -left-5 translate-x-1/2 flex text-xs flex-col items-center justify-center rounded-md bg-black z-50 shadow-xl px-4 py-2"
            >
              {/* Tooltip content */}
              <div className="absolute inset-x-10 z-30 w-[20%] -bottom-px bg-gradient-to-r from-transparent via-emerald-500 to-transparent h-px" />
              <div className="absolute left-10 w-[40%] z-30 -bottom-px bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px" />
              <div className="font-bold text-white relative z-30 text-base">{item.name}</div>
              <div className="text-white text-xs">{item.designation}</div>
            </motion.div>
          )}

          {/* Image or trigger element */}
          <img
            onMouseMove={handleMouseMove}
            height={200}
            width={700}
            src={item.image}
            alt={item.name}
            className={`object-cover m-0 p-0 object-top rounded-full ${screenWidth < 640 ? 'h-20 w-20' : 'h-40 w-40'} border-2 group-hover:scale-105 group-hover:z-30 relative transition duration-500`}
          />
        </div>
        </div>
      ))}
    </>
  );
};