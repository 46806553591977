import React from "react";
import { motion } from "framer-motion";
import "@fortawesome/fontawesome-free/css/all.css";
import SirMudaasir from "../Images/sirMudassir.jpg";
import SirToqir from "../Images/sirtoqirrr.jpg";

const CoFounderCards = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-8" style={{margin:"5%"}}>
      {/* First Card */}
      <motion.div
        className="flex flex-col md:flex-row text-gray-700 bg-white shadow-md rounded-xl"
        style={{ width: "80%", maxWidth: "35rem" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {/* Content (Name and Picture) on the Left */}
        <div className="w-full md:w-2/5" style={{paddingTop:"5%"}}>
          <div className="mx-auto mt-4 md:mt-0 overflow-hidden bg-white rounded-full h-32 md:h-64 w-32 md:w-60">
            <img
              src={SirToqir}
              alt="profile-picture"
              className={`object-cover rounded-full ${window.innerWidth < 768 ? 'w-full h-full' : 'w-48 h-48'}`}
              style={{padding:"5%" }}
            />
          </div>
          <div className="p-6 text-center">
            <h4 className="mb-2 text-2xl font-semibold text-blue-gray-900">
              Dr. Toqir Rana
            </h4>
            <p className="text-base font-medium text-blue-gray-400">
              Director / Co-Founder
            </p>
          </div>
        </div>
        
        {/* Expanded Content on the Right */}
        <motion.div
          className="w-full md:w-3/5 p-6 bg-gray-100 rounded-lg shadow-md"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
         
          <p className="font-semibold mb-4">Associate Professor</p>
          <p className="font-semibold">Ph.D. Natural Language Processing</p>
          <p className="font-semibold mb-4">MS Computer Science</p>
          <p>
            <span className="font-semibold">Expertise:</span> NLP, Text Analysis, Data Mining, Data Analytics, Generative AI, Machine Learning, Deep Learning
          </p>
          <p className="mt-4"><span className="font-semibold">Email:</span> toqirr@gmail.com</p>
        </motion.div>
      </motion.div>

      {/* Second Card */}
      <motion.div
        className="flex flex-col md:flex-row text-gray-700 bg-white shadow-md rounded-xl"
        style={{ width: "80%", maxWidth: "35rem" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {/* Content (Name and Picture) on the Left */}
        <div className="w-full md:w-2/5" style={{paddingTop:"5%"}}>
          <div className="mx-auto mt-4 md:mt-0 overflow-hidden bg-white rounded-full h-32 md:h-64 w-32 md:w-60">
            <img
              src={SirMudaasir}
              alt="profile-picture"
              className={`object-cover rounded-full ${window.innerWidth < 768 ? 'w-full h-full' : 'w-48 h-48'}`}
              style={{padding:"5%"}}
            />
          </div>
          <div className="p-6 text-center">
            <h4 className="mb-2 text-2xl font-semibold text-blue-gray-900">
              Dr. Mudasser
            </h4>
            <p className="text-base font-medium text-blue-gray-400">
              Director / Co-Founder
            </p>
          </div>
        </div>
        
        {/* Expanded Content on the Right */}
        <motion.div
          className="w-full md:w-3/5 p-6 bg-gray-100 rounded-lg shadow-md"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {/* <p className="font-semibold">M.Sc. Statistics</p> */}
          <p className="font-semibold mb-4 ">Associate Professor</p>
          <p className="font-semibold">Ph.D. Pattern Recongination and Intellignet Systems</p>
          <p className="font-semibold ">MS Computer Science</p>
          <p className="font-semibold">M.Phil. Statistics</p>
          <p className="font-semibold mb-4">MBA Finance</p>
         
          
          <p>
            <span className="font-semibold">Expertise:</span> Data Science, Data Analytics, Machine Learning, Deep Learning, Artificial Intelligence, Business Statistics
          </p>
          <p className="mt-4"><span className="font-semibold">Email:</span>{" "}
            <span className="break-all">mudaser1@yahoo.com</span>
          </p>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default CoFounderCards;
