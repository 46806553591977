import React from "react";
import './Welcome.css';
import useMediaQuery from '@mui/material/useMediaQuery';
const Welcome = () => {
  const isMobile = useMediaQuery('(max-width: 550px)');
  return (
    <div>
      {isMobile ? (
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <p className="paragraph">We at Data Unfolding are your data powerhouse. We don't just store your information, we crack its code. Using cutting-edge tools and advanced analytics, we unlock hidden insights from your data, transforming it into actionable intelligence that fuels smarter decision-making for your business.</p>
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 0.3 }}>
            <div className="welcome">Welcome</div>
          </div>
          <div style={{ flex: 0.7, marginRight: '30px'}}>
            <p style={{textAlign:'justify'}} className="paragraph">We at Data Unfolding are your data powerhouse. We don't just store your information, we crack its code. Using cutting-edge tools and advanced analytics, we unlock hidden insights from your data, transforming it into actionable intelligence that fuels smarter decision-making for your business.</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Welcome;