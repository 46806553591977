"use client";
import React, { useRef } from "react";
import { Boxes } from "../ui/background-boxes.tsx";
import Services from "../Services/Services.jsx";
import Welcome from "../Welcome/Welcome.jsx";
import { useNavigate } from 'react-router-dom';
import "./Main.css"
import KeyDifferentiators from "../Services/KeyDifferentiators.jsx";
import useMediaQuery from '@mui/material/useMediaQuery';
function Main() {

  const isTablet = useMediaQuery('(max-width: 1100px)');
  const navigate = useNavigate();
  const servicesRef = useRef(null);
  const diffRef = useRef(null);
  const ContactButton = () => {
    navigate('/contactus');
  };
  const ServiceButton = () => {
    if (servicesRef.current) {
      servicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const keyButton = () => {
    if (servicesRef.current) {
      diffRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (

    <>
      <div style={{ width: "100%" }} className="container">
      {isTablet?
      (<><h1 className="heading1" style={{ "zindex": 20, fontFamily: "'introcatch', sans-serif" }}>
      Unveiling Patterns, 
          </h1><h1 className="heading2">
          Unleashing Potentials.
            </h1><p style={{ opacity: 0.7, zIndex: 20 }} className="p2">
              We unlock hidden potential in your data using
            </p><p className="p1" style={{ opacity: 0.7, zIndex: 20 }}>
              advanced analytics to drive smarter decisions.
            </p><div class="buttonsDiv">

              <button style={{
                fontSize: "1.6rem",
                fontFamily: "Roboto, sans-serif"
              }}
                className="button1"
                onClick={ContactButton}>
                Contact Us
              </button>
              <button
                style={{
                  fontSize: "1.6rem",
                  fontFamily: "Roboto, sans-serif",
                }}
                className="button23"
                onClick={ServiceButton}>
                Services
              </button>
              <button style={{
                fontSize: "1.3rem",
                fontFamily: "Roboto, sans-serif",
              }}
                className="button23"
                onClick={keyButton}>
                Key Differentiators
              </button>
            </div></>):
      (<><h1 className="heading1Desktop" style={{ "zindex": 20, fontFamily: "'introcatch', sans-serif" }}>
            Unveiling Patterns, Unleashing Potentials.
          </h1><h1 className="heading2">
            </h1><p style={{ opacity: 0.7, zIndex: 20 }} className="p2Desktop">
            We unlock hidden potential in your data using
            </p><p className="p1Desktop" style={{ opacity: 0.7, zIndex: 20 }}>
            advanced analytics to drive smarter decisions.
            </p><div class="buttonsDiv">

              <button style={{
                fontSize: "1.6rem",
                fontFamily: "Roboto, sans-serif"
              }}
                className="button1"
                onClick={ContactButton}>
                Contact Us
              </button>
              <button
                style={{
                  
                  fontFamily: "Roboto, sans-serif",
                }}
                className="button23"
                onClick={ServiceButton}>
                Services
              </button>
              <button style={{
                fontSize: "1.3rem",
                fontFamily: "Roboto, sans-serif",
              }}
                className="button23"
                onClick={keyButton}>
                Key Differentiators
              </button>
            </div></>)}
        
        <Boxes />
      </div>
      <Welcome />

      <div id="service" ref={servicesRef}><Services /></div>
      <div id="keyDiffs" ref={diffRef}><KeyDifferentiators /></div>

    </>

  );
}

export default Main;
