import React from "react";

const ServiceDescriptionDesktop = ({ fontSize, fontWeight, fontFamily, color, text }) => {
    return (
      <div style={{ textAlign: "center" }}>
        <p
          style={{
            fontSize,
            fontWeight,
            fontFamily,
            color,
          }}
        >
          {text}
        </p>
      </div>
    );
  };
  
  export default ServiceDescriptionDesktop;